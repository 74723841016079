import axios from 'axios'

export default class ApiService {
  constructor () {
    axios.defaults.baseURL = process.env.REACT_APP_APIHOST
    axios.defaults.headers['Content-Type'] = 'application/json'
    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        const accessToken = this.getToken()

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = accessToken.replaceAll('"', '')
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        //const { config, response } = error
        
        // ** if (status === 401) {
        // if (response && response.status === 401) {
        //   this.setToken(null)
        // }
        return Promise.reject(error)
      }
    )
  }

  getToken() {
    return localStorage.getItem('accessToken')
  }

  setToken(token = null) {
    localStorage.setItem('accessToken', token)
  }

  getSystemInfo() {
    return axios.get('/system/info')
  }

  getAuth() {
    return axios.get('/auth/get')
  }

  authSignin(email, password) {
    return axios.post('/auth/signin', { email, password })
  }

  authForgot(email) {
    return axios.post('/auth/forgot-password', { email })
  }

  authForgotCheck(userId, passwordHash) {
    return axios.get(`/auth/forgot-password/${userId}/${passwordHash}`)
  }

  authForgotFinish(userId, passwordHash, newPassword, newPasswordConfirm) {
    return axios.post(`/auth/forgot-password/${userId}/${passwordHash}`, {
      newPassword, newPasswordConfirm
    })
  }

  authGetData() {
    return axios.get(`/auth/user`)
  }

  authInviteCheck(inviteId, inviteCode) {
    return axios.get(`/auth/invite/${inviteId}/${inviteCode}`)
  }

  authInviteFinish(inviteId, inviteCode, lastName, firstName, password, passwordConfirm) {
    return axios.post(`/auth/invite/${inviteId}/${inviteCode}`, {
      lastName, firstName, password, passwordConfirm
    })
  }

  authSignupSchool(email, name, key, phone) {
    return axios.post('/auth/signup/school', { email, name, key, phone })
  }

  studentSettingsSetInfo(firstName, lastName, email, phone) {
    return axios.put('/student/settings/info', { firstName, lastName, email, phone })
  }

  studentSettingsSetPassword(currentPassword, newPassword, newPasswordConfirm) {
    return axios.put('/student/settings/password', { currentPassword, newPassword, newPasswordConfirm })
  }

  studentGetFondyLink(amount, userPlanPackId = null) {
    return axios.post('/student/settings/pay/fondy', { amount, userPlanPackId  })
  }

  studentGetWayforpayLink(amount, userPlanPackId = null) {
    return axios.post('/student/settings/pay/wayforpay', { amount, userPlanPackId  })
  }

  studentGetFondyOrderStatus(orderId) {
    return axios.get(`/student/settings/pay/fondy/check/${orderId}`)
  }

  studentGetWayforpayOrderStatus(orderId) {
    return axios.get(`/student/settings/pay/wayforpay/check/${orderId}`)
  }

  schoolAdminGetFondyLink(amount) {
    return axios.post('/schoolAdmin/schoolSettings/pay/fondy', { amount  })
  }

  schoolAdminGetFondyOrderStatus(orderId) {
    return axios.get(`/schoolAdmin/schoolSettings/pay/fondy/check/${orderId}`)
  }

  schoolAdminGetWayforpayLink(amount) {
    return axios.post('/schoolAdmin/schoolSettings/pay/wayforpay', { amount  })
  }

  schoolAdminGetWayforpayOrderStatus(orderId) {
    return axios.get(`/schoolAdmin/schoolSettings/pay/wayforpay/check/${orderId}`)
  }

  schoolAdminPayPlatformPlan(params) {
    return axios.post('/schoolAdmin/schoolSettings/payPlatformPlan', params)
  }

  schoolAdminPayPlatformPlanChangeStudents(params) {
    return axios.post('/schoolAdmin/schoolSettings/payPlatformPlanChangeStudents', params)
  }

  studentNotificationsGet(params) {
    return axios.get('/student/notifications', { params })
  }

  studentPlansList(params) {
    return axios.get('/student/plans', { params })
  }

  studentPlansPay(params) {
    return axios.post('/student/plans/pay', params)
  }

  studentClassesList(params) {
    return axios.get('/student/classes', { params })
  }

  studentScheduleGet(params) {
    return axios.get('/student/schedule', { params })
  }

  studentVocabularyList(params) {
    return axios.get('/student/vocabulary', { params })
  }

  studentVocabularyCreate(params) {
    return axios.post('/student/vocabulary', params)
  }

  studentVocabularyDelete(id) {
    return axios.delete(`/student/vocabulary/${id}`)
  }

  schoolAdminVocabularyList(params) {
    return axios.get('/schoolAdmin/vocabulary', { params })
  }

  schoolAdminVocabularyUsers(params) {
    return axios.get('/schoolAdmin/vocabulary/users', { params })
  }

  teacherVocabularyUsers(params) {
    return axios.get('/teacher/vocabulary/users', { params })
  }

  schoolAdminVocabularyCreate(params) {
    return axios.post('/schoolAdmin/vocabulary', params)
  }

  schoolAdminVocabularyDelete(id) {
    return axios.delete(`/schoolAdmin/vocabulary/${id}`)
  }

  studentClassCoursesList(classId, params) {
    return axios.get(`/student/classes/${classId}/courses`, { params })
  }

  studentClassCoursesGet(classId, courseId) {
    return axios.get(`/student/classes/${classId}/courses/${courseId}`)
  }

  studentClassAddAnswer(classId, exerciseId, isMultipleAnswers, answer) {
    return axios.post(`/student/classes/${classId}/answer`, {
      exerciseId,
      isMultipleAnswers,
      answer
    })
  }

  teacherClassAddAnswer(classId, exerciseId, isMultipleAnswers, answer) {
    return axios.post(`/teacher/classes/${classId}/answer`, {
      exerciseId,
      isMultipleAnswers,
      answer
    })
  }

  teacherClassUpdateRoomLink(classId, roomLink) {
    return axios.post(`/teacher/classes/${classId}/roomLink`, {
      roomLink
    })
  }

  schoolAdminClassUpdateRoomLink(classId, roomLink) {
    return axios.post(`/schoolAdmin/classes/${classId}/roomLink`, {
      roomLink
    })
  }

  schoolAdminClassAddAnswer(classId, exerciseId, isMultipleAnswers, answer) {
    return axios.post(`/schoolAdmin/classes/${classId}/answer`, {
      exerciseId,
      isMultipleAnswers,
      answer
    })
  }

  teacherSettingsSetInfo(firstName, lastName, email, phone, zoomLink) {
    return axios.put('/teacher/settings/info', { firstName, lastName, email, phone, zoomLink })
  }

  teacherSettingsSetPassword(currentPassword, newPassword, newPasswordConfirm) {
    return axios.put('/teacher/settings/password', { currentPassword, newPassword, newPasswordConfirm })
  }

  teacherVocabularyList(params) {
    return axios.get('/teacher/vocabulary', { params })
  }

  teacherVocabularyCreate(params) {
    return axios.post('/teacher/vocabulary', params)
  }

  teacherVocabularyDelete(id) {
    return axios.delete(`/teacher/vocabulary/${id}`)
  }

  teacherClassesList(params) {
    return axios.get('/teacher/classes', { params })
  }

  teacherClassesGet(id) {
    return axios.get(`/teacher/classes/${id}`)
  }

  teacherClassCoursesList(params) {
    return axios.get('/teacher/classes/courses', { params })
  }

  teacherClassCoursesGet(courseId, lessonId) {
    return axios.get(`/teacher/courses/${courseId}/lessons/${lessonId}`)
  }

  teacherClassCourseShare(classId, params) {
    return axios.post(`/teacher/classes/${classId}/shareCourse`, params)
  }

  teacherClassHomeworkList(classId, params) {
    return axios.get(`/teacher/classes/${classId}/homework`, { params })
  }

  teacherClassLearnedLessonsList(classId, params) {
    return axios.get(`/teacher/classes/${classId}/learnedCourses`, { params })
  }

  studentClassLearnedLessonsList(classId, params) {
    return axios.get(`/student/classes/${classId}/learnedCourses`, { params })
  }

  schoolAdminClassLearnedLessonsList(classId, params) {
    return axios.get(`/schoolAdmin/classes/${classId}/learnedCourses`, { params })
  }

  studentClassHomeworkList(classId, params) {
    return axios.get(`/student/classes/${classId}/homework`, { params })
  }

  teacherClassHomeworkGet(classId, homeworkId, params) {
    return axios.get(`/teacher/classes/${classId}/homework/${homeworkId}`, { params })
  }

  teacherClassHomeworkDelete(classId, homeworkId, exerciseId, params) {
    return axios.delete(`/teacher/classes/${classId}/homework/${homeworkId}/exercises/${exerciseId}`, { params })
  }

  studentClassHomeworkGet(classId, homeworkId, params) {
    return axios.get(`/student/classes/${classId}/homework/${homeworkId}`, { params })
  }

  studentClassHomeworkSendToCheck(classId, homeworkId, params) {
    return axios.put(`/student/classes/${classId}/homework/${homeworkId}/sendToCheck`, { params })
  }

  teacherClassHomeworkChangeStatus(classId, homeworkId, params) {
    return axios.put(`/teacher/classes/${classId}/homework/${homeworkId}/changeStatus`, params)
  }

  teacherClassCreateHomework(classId, params) {
    return axios.post(`/teacher/classes/${classId}/homework`, params)
  }

  schoolAdminClassHomeworkList(classId, params) {
    return axios.get(`/schoolAdmin/classes/${classId}/homework`, { params })
  }

  schoolAdminClassHomeworkGet(classId, homeworkId, params) {
    return axios.get(`/schoolAdmin/classes/${classId}/homework/${homeworkId}`, { params })
  }

  schoolAdminClassHomeworkDelete(classId, homeworkId, exerciseId, params) {
    return axios.delete(`/schoolAdmin/classes/${classId}/homework/${homeworkId}/exercises/${exerciseId}`, { params })
  }

  schoolAdminClassHomeworkChangeStatus(classId, homeworkId, params) {
    return axios.put(`/schoolAdmin/classes/${classId}/homework/${homeworkId}/changeStatus`, params)
  }

  schoolAdminClassCreateHomework(classId, params) {
    return axios.post(`/schoolAdmin/classes/${classId}/homework`, params)
  }

  teacherClassAttachCourse(classId, lessonId) {
    return axios.put(`/teacher/classes/${classId}/attachLesson/${lessonId}`)
  }

  teacherClassDetachCourse(classId) {
    return axios.delete(`/teacher/classes/${classId}/detachLesson`)
  }

  schoolAdminClassDetachCourse(classId) {
    return axios.delete(`/teacher/classes/${classId}/detachLesson`)
  }

  teacherClassChangeExerciseSetting(classId, lessonId, exerciseId, params) {
    return axios.put(`/teacher/classes/${classId}/changeExerciseSetting/${lessonId}/${exerciseId}`, params)
  }

  teacherClassClearExerciseAnswers(classId, lessonId, exerciseId, params) {
    return axios.post(`/teacher/classes/${classId}/clearExerciseAnswers/${lessonId}/${exerciseId}`, params)
  }

  schoolAdminClassesList(params) {
    return axios.get('/schoolAdmin/classes', { params })
  }

  schoolAdminClassesGet(id) {
    return axios.get(`/schoolAdmin/classes/${id}`)
  }

  schoolAdminClassCoursesList(params) {
    return axios.get('/schoolAdmin/classes/courses', { params })
  }

  schoolAdminClassCoursesGet(courseId, lessonId) {
    return axios.get(`/schoolAdmin/courses/${courseId}/lessons/${lessonId}`)
  }

  schoolAdminClassCourseShare(classId, params) {
    return axios.post(`/schoolAdmin/classes/${classId}/shareCourse`, params)
  }

  schoolAdminClassAttachCourse(classId, lessonId) {
    return axios.put(`/schoolAdmin/classes/${classId}/attachLesson/${lessonId}`)
  }

  schoolAdminClassChangeExerciseSetting(classId, lessonId, exerciseId, params) {
    return axios.put(`/schoolAdmin/classes/${classId}/changeExerciseSetting/${lessonId}/${exerciseId}`, params)
  }

  schoolAdminClassClearExerciseAnswers(classId, lessonId, exerciseId, params) {
    return axios.post(`/schoolAdmin/classes/${classId}/clearExerciseAnswers/${lessonId}/${exerciseId}`, params)
  }

  teacherAnalytics(params) {
    return axios.get('/teacher/analytics', { params })
  }

  studentClassesGet(id, lessonId = null) {
    return axios.get(!lessonId ? `/student/classes/${id}` : `/student/classes/${id}?lessonId=${lessonId}`)
  }

  teacherClassesEndPersonalLesson(classId) {
    return axios.get(`/teacher/classes/${classId}/endPersonalLesson`)
  }

  teacherClassesEndGroupLesson(classId, students) {
    return axios.post(`/teacher/classes/${classId}/endGroupLesson`, { students })
  }

  schoolAdminClassesEndPersonalLesson(classId) {
    return axios.get(`/schoolAdmin/classes/${classId}/endPersonalLesson`)
  }

  schoolAdminClassesEndGroupLesson(classId, students) {
    return axios.post(`/schoolAdmin/classes/${classId}/endGroupLesson`, { students })
  }

  schoolAdminScheduleMyGet(params) {
    return axios.get('/schoolAdmin/schedule-my', { params })
  }

  schoolAdminScheduleMyGetDetail(params) {
    return axios.get('/schoolAdmin/schedule-my/detail', { params })
  }

  schoolAdminScheduleMyUpdateLesson(params) {
    return axios.put('/schoolAdmin/schedule-my/lesson', params)
  }

  schoolAdminScheduleMyDeleteLesson(id) {
    return axios.delete(`/schoolAdmin/schedule-my/lesson/${id}`)
  }

  schoolAdminScheduleMyCreateLesson(params) {
    return axios.post('/schoolAdmin/schedule-my/lesson', params)
  }

  schoolAdminScheduleMyGetStudentsAndGroupsForLesson(params) {
    return axios.get('/schoolAdmin/schedule-my/lesson/students-groups', { params })
  }


  teacherScheduleGet(params) {
    return axios.get('/teacher/schedule', { params })
  }

  teacherScheduleGetDetail(params) {
    return axios.get('/teacher/schedule/detail', { params })
  }

  studentScheduleGetDetail(params) {
    return axios.get('/student/schedule/detail', { params })
  }

  teacherScheduleUpdateLesson(params) {
    return axios.put('/teacher/schedule/lesson', params)
  }

  teacherScheduleDeleteLesson(id) {
    return axios.delete(`/teacher/schedule/lesson/${id}`)
  }

  teacherScheduleCreateLesson(params) {
    return axios.post('/teacher/schedule/lesson', params)
  }

  teacherScheduleGetStudentsAndGroupsForLesson(params) {
    return axios.get('/teacher/schedule/lesson/students-groups', { params })
  }

  schoolAdminSettingsSetInfo(firstName, lastName, email) {
    return axios.put('/schoolAdmin/settings/info', { firstName, lastName, email })
  }

  schoolAdminSettingsSetPassword(currentPassword, newPassword, newPasswordConfirm) {
    return axios.put('/schoolAdmin/settings/password', { currentPassword, newPassword, newPasswordConfirm })
  }

  schoolAdminGetAdmins(params) {
    return axios.get('/schoolAdmin/admins', { params })
  }

  schoolAdminGetSchoolsPlans(params) {
    return axios.get('/schoolAdmin/schoolsPlans', { params })
  }

  schoolAdminCreateSchoolsPlans(params) {
    return axios.post('/schoolAdmin/schoolsPlans', params)
  }

  schoolAdminEditSchoolsPlans(id, params) {
    return axios.put(`/schoolAdmin/schoolsPlans/${id}`, params)
  }

  schoolAdminArchiveSchoolsPlan(id) {
    return axios.put(`/schoolAdmin/schoolsPlans/${id}/archive`)
  }

  schoolAdminLessonsStudentsList(params) {
    return axios.get('/schoolAdmin/lessons/students', { params })
  }

  schoolAdminLessonsStudentsGet(id) {
    return axios.get(`/schoolAdmin/lessons/students/${id}`)
  }

  schoolAdminLessonsStudentsClassesList(id, params) {
    return axios.get(`/schoolAdmin/lessons/students/${id}/classes`, { params })
  }

  schoolAdminLessonsStudentsClassesCreate(id, params) {
    return axios.post(`/schoolAdmin/lessons/students/${id}/classes`, params)
  }

  schoolAdminLessonsStudentsClassesDelete(userId, id) {
    return axios.delete(`/schoolAdmin/lessons/students/${userId}/classes/${id}`)
  }

  schoolAdminLessonsStudentsDetailClassesScheduleGet(userId, id, params) {
    return axios.get(`/schoolAdmin/lessons/students/${userId}/classes/${id}/schedule`, { params })
  }

  schoolAdminLessonsGroupsDetailClassesScheduleGet(groupId, params) {
    return axios.get(`/schoolAdmin/lessons/groups/${groupId}/schedule`, { params })
  }

  schoolAdminLessonsStudentsClassesChangeTeacher(userId, id, data) {
    return axios.post(`/schoolAdmin/lessons/students/${userId}/classes/${id}/changeTeacher`, data)
  }

  schoolAdminLessonsStudentsClassesChangePlan(classId, data) {
    return axios.post(`/schoolAdmin/lessons/students/classes/${classId}/changeClassPlan`, data)
  }

  schoolAdminLessonsTeachersList(params) {
    return axios.get('/schoolAdmin/lessons/teachers', { params })
  }

  schoolAdminLessonsTeachersGet(id) {
    return axios.get(`/schoolAdmin/lessons/teachers/${id}`)
  }

  schoolAdminLessonsTeachersClassesList(id, params) {
    return axios.get(`/schoolAdmin/lessons/teachers/${id}/classes`, { params })
  }

  schoolAdminLessonsTeachersGetSchedule(id, params) {
    return axios.get(`/schoolAdmin/lessons/teachers/${id}/schedule`, { params })
  }

  schoolAdminLessonsGroupsList(params) {
    return axios.get('/schoolAdmin/lessons/groups', { params })
  }

  schoolAdminLessonsGroupsCreate(params) {
    return axios.post('/schoolAdmin/lessons/groups', params)
  }

  schoolAdminLessonsGroupsGet(id) {
    return axios.get(`/schoolAdmin/lessons/groups/${id}`)
  }

  schoolAdminLessonsGroupsUpdate(id, params) {
    return axios.put(`/schoolAdmin/lessons/groups/${id}`, params)
  }

  schoolAdminLessonsGroupsGetVisits(groupId, startDate, endDate) {
    return axios.post(`/schoolAdmin/lessons/groups/${groupId}/visits`, {
      startDate,
      endDate
    })
  }

  schoolAdminLessonsGroupsDelete(id) {
    return axios.delete(`/schoolAdmin/lessons/groups/${id}`)
  }

  schoolAdminLessonsGroupsStudentsList(id, params) {
    return axios.get(`/schoolAdmin/lessons/groups/${id}/students`, { params })
  }

  schoolAdminLessonsGroupsStudentsToAttach(id, params) {
    return axios.get(`/schoolAdmin/lessons/groups/${id}/studentsToAttach`, { params })
  }

  schoolAdminLessonsGroupsStudentsAttach(id, userId) {
    return axios.post(`/schoolAdmin/lessons/groups/${id}/students/${userId}`)
  }

  schoolAdminLessonsGroupsStudentsDetach(id, userId) {
    return axios.delete(`/schoolAdmin/lessons/groups/${id}/students/${userId}`)
  }

  schoolAdminLessonsGroupsTeachersToAttach(params) {
    return axios.get(`/schoolAdmin/lessons/groups/teachers`, { params })
  }

  schoolAdminLessonsGroupsPlansToAttach(params) {
    return axios.get(`/schoolAdmin/lessons/groups/plans`, { params })
  }

  schoolAdminLessonsStudentsPlansToAttach(params) {
    return axios.get(`/schoolAdmin/lessons/students/plans`, { params })
  }

  schoolAdminInviteAdmin(email) {
    return axios.post('/schoolAdmin/admins/invite', { email })
  }

  schoolAdminCreateAdmin(params) {
    return axios.post('/schoolAdmin/admins', params)
  }

  schoolAdminGetAdmin(id) {
    return axios.get(`/schoolAdmin/admins/${id}`)
  }

  schoolAdminUpdateAdmin(id, params) {
    return axios.put(`/schoolAdmin/admins/${id}`, params)
  }

  schoolAdminDeleteAdmin(id) {
    return axios.delete(`/schoolAdmin/admins/${id}`)
  }

  schoolAdminGetTeachers(params) {
    return axios.get('/schoolAdmin/teachers', { params })
  }

  schoolAdminInviteTeacher(email) {
    return axios.post('/schoolAdmin/teachers/invite', { email })
  }

  schoolAdminCreateTeacher(params) {
    return axios.post('/schoolAdmin/teachers', params)
  }

  schoolAdminGetTeacher(id) {
    return axios.get(`/schoolAdmin/teachers/${id}`)
  }

  schoolAdminUpdateTeacher(id, params) {
    return axios.put(`/schoolAdmin/teachers/${id}`, params)
  }

  schoolAdminDeleteTeacher(id) {
    return axios.delete(`/schoolAdmin/teachers/${id}`)
  }

  schoolAdminGetStudents(params) {
    return axios.get('/schoolAdmin/students', { params })
  }

  schoolAdminInviteStudent(email) {
    return axios.post('/schoolAdmin/students/invite', { email })
  }

  schoolAdminCreateStudent(params) {
    return axios.post('/schoolAdmin/students', params)
  }

  schoolAdminGetStudent(id) {
    return axios.get(`/schoolAdmin/students/${id}`)
  }

  schoolAdminUpdateStudent(id, params) {
    return axios.put(`/schoolAdmin/students/${id}`, params)
  }

  schoolAdminDeleteStudent(id) {
    return axios.delete(`/schoolAdmin/students/${id}`)
  }

  schoolAdminStudentGetSchoolPlansToSet(id) {
    return axios.get(`/schoolAdmin/students/${id}/getSchoolPlanPack`)
  }

  schoolAdminStudentSetSchoolPlan(id, planPackId) {
    return axios.post(`/schoolAdmin/students/${id}/setSchoolPlanPack`, { planPackId })
  }

  schoolAdminStudentDeleteSchoolPlan(id, planPackId) {
    return axios.post(`/schoolAdmin/students/${id}/deleteSchoolPlanPack`, { planPackId })
  }

  schoolAdminStudentChargeOffSchoolPlan(id, planPackId, count) {
    return axios.post(`/schoolAdmin/students/${id}/chargeOffSchoolPlanPack`, { planPackId, count })
  }

  schoolAdminGetCourses(params) {
    return axios.get('/schoolAdmin/courses', { params })
  }

  schoolAdminGetCourse(id) {
    return axios.get(`/schoolAdmin/courses/${id}`)
  }

  teacherGetCourses(params) {
    return axios.get('/teacher/courses', { params })
  }

  teacherGetCourse(id) {
    return axios.get(`/teacher/courses/${id}`)
  }

  schoolAdminCreateCourse(params) {
    return axios.post('/schoolAdmin/courses', params)
  }

  schoolAdminUpdateCourse(courseId, params) {
    return axios.put(`/schoolAdmin/courses/${courseId}`, params)
  }

  schoolAdminDeleteCourse(id) {
    return axios.delete(`/schoolAdmin/courses/${id}`)
  }

  adminSchoolsTeachers(schoolId, params) {
    return axios.get(`/admin/schools/${schoolId}/teachers`, { params })
  }

  adminSchoolsAdmins(schoolId, params) {
    return axios.get(`/admin/schools/${schoolId}/admins`, { params })
  }

  adminSchoolsStudents(schoolId, params) {
    return axios.get(`/admin/schools/${schoolId}/students`, { params })
  }

  adminSchoolsPlans(schoolId, params) {
    return axios.get(`/admin/schools/${schoolId}/plans`, { params })
  }

  schoolAdminChangePositionCourse(params) {
    return axios.put(`/schoolAdmin/courses/change-position`, params)
  }

  adminHandbookChangePositionCourse(params) {
    return axios.put(`/admin/handbook/change-position`, params)
  }

  schoolAdminCreateCourseSection(courseId, params) {
    return axios.post(`/schoolAdmin/courses/${courseId}/sections`, params)
  }

  schoolAdminUpdateCourseSection(courseId, courseSectionId, params) {
    return axios.put(`/schoolAdmin/courses/${courseId}/sections/${courseSectionId}`, params)
  }

  schoolAdminDeleteCourseSection(courseId, courseSectionId) {
    return axios.delete(`/schoolAdmin/courses/${courseId}/sections/${courseSectionId}`)
  }

  schoolAdminGetCourseLesson(courseId, courseLessonId, params) {
    return axios.get(`/schoolAdmin/courses/${courseId}/lessons/${courseLessonId}`, params)
  }

  schoolAdminCreateCourseLesson(courseId, params) {
    return axios.post(`/schoolAdmin/courses/${courseId}/lessons`, params)
  }

  schoolAdminEditCourseLesson(courseId, courseLessonId, params) {
    return axios.put(`/schoolAdmin/courses/${courseId}/lessons/${courseLessonId}`, params)
  }

  schoolAdminDeleteCourseLesson(courseId, courseLessonId) {
    return axios.delete(`/schoolAdmin/courses/${courseId}/lessons/${courseLessonId}`)
  }

  schoolAdminCourseSectionChangePosition(courseId, courseLessonId, data) {
    return axios.post(`/schoolAdmin/courses/${courseId}/sections/change-position`, data)
  }

  schoolAdminCourseLessonChangePosition(courseId, courseLessonId, data) {
    return axios.post(`/schoolAdmin/courses/${courseId}/lessons/change-position`, data)
  }

  schoolAdminCreateLessonSection(courseId, lessonId, params) {
    return axios.post(`/schoolAdmin/courses/${courseId}/lessons/${lessonId}/sections`, params)
  }

  schoolAdminUpdateLessonSection(courseId, lessonId, sectionId, params) {
    return axios.put(`/schoolAdmin/courses/${courseId}/lessons/${lessonId}/sections/${sectionId}`, params)
  }

  schoolAdminDeleteLessonSection(courseId, lessonId, sectionId) {
    return axios.delete(`/schoolAdmin/courses/${courseId}/lessons/${lessonId}/sections/${sectionId}`)
  }

  schoolAdminCreateLessonExercise(courseId, lessonId, sectionId, params) {
    return axios.post(`/schoolAdmin/courses/${courseId}/lessons/${lessonId}/sections/${sectionId}/exercises`, params)
  }

  schoolAdminEditLessonExercise(courseId, lessonId, sectionId, exerciseId, params) {
    return axios.post(`/schoolAdmin/courses/${courseId}/lessons/${lessonId}/sections/${sectionId}/exercises/${exerciseId}`, params)
  }

  schoolAdminDeleteLessonExercise(courseId, lessonId, sectionId, exerciseId) {
    return axios.delete(`/schoolAdmin/courses/${courseId}/lessons/${lessonId}/sections/${sectionId}/exercises/${exerciseId}`)
  }

  schoolAdminUpLessonExercise(courseId, lessonId, sectionId, exerciseId) {
    return axios.put(`/schoolAdmin/courses/${courseId}/lessons/${lessonId}/sections/${sectionId}/exercises/${exerciseId}/up`)
  }

  schoolAdminDownLessonExercise(courseId, lessonId, sectionId, exerciseId) {
    return axios.put(`/schoolAdmin/courses/${courseId}/lessons/${lessonId}/sections/${sectionId}/exercises/${exerciseId}/down`)
  }

  schoolAdminUpLessonSection(courseId, lessonId, sectionId) {
    return axios.put(`/schoolAdmin/courses/${courseId}/lessons/${lessonId}/sections/${sectionId}/up`)
  }

  schoolAdminDownLessonSection(courseId, lessonId, sectionId) {
    return axios.put(`/schoolAdmin/courses/${courseId}/lessons/${lessonId}/sections/${sectionId}/down`)
  }

  schoolAdminGetCourses(params) {
    return axios.get('/schoolAdmin/courses', { params })
  }

  schoolAdminGetCourse(id) {
    return axios.get(`/schoolAdmin/courses/${id}`)
  }

  schoolAdminCoursesCopyMove(params) {
    return axios.get(`/schoolAdmin/courses-copy-move/courses`, { params })
  }

  schoolAdminCoursesSectionsCopyMove(params) {
    return axios.get(`/schoolAdmin/courses-copy-move/coursesSections`, { params })
  }

  schoolAdminCoursesLessonsCopyMove(params) {
    return axios.get(`/schoolAdmin/courses-copy-move/coursesLessons`, { params })
  }

  schoolAdminCoursesLessonsSectionsCopyMove(params) {
    return axios.get(`/schoolAdmin/courses-copy-move/coursesLessonsSections`, { params })
  }

  schoolAdminCoursesLessonsExerciseCopy(params) {
    return axios.post(`/schoolAdmin/courses-copy-move/copyExercise`, params )
  }

  schoolAdminCoursesLessonsExerciseMove(params) {
    return axios.post(`/schoolAdmin/courses-copy-move/moveExercise`, params )
  }

  schoolAdminCoursesLessonCopy(params) {
    return axios.post(`/schoolAdmin/courses-copy-move/copyLesson`, params )
  }

  schoolAdminCoursesLessonMove(params) {
    return axios.post(`/schoolAdmin/courses-copy-move/moveLesson`, params )
  }

  teacherGetCourses(params) {
    return axios.get('/teacher/courses', { params })
  }

  teacherGetCourse(id) {
    return axios.get(`/teacher/courses/${id}`)
  }

  schoolAdminCreateCourse(params) {
    return axios.post('/schoolAdmin/courses', params)
  }

  schoolAdminUpdateCourse(courseId, params) {
    return axios.put(`/schoolAdmin/courses/${courseId}`, params)
  }

  schoolAdminDeleteCourse(id) {
    return axios.delete(`/schoolAdmin/courses/${id}`)
  }

  schoolAdminCreateCourseSection(courseId, params) {
    return axios.post(`/schoolAdmin/courses/${courseId}/sections`, params)
  }

  schoolAdminUpdateCourseSection(courseId, courseSectionId, params) {
    return axios.put(`/schoolAdmin/courses/${courseId}/sections/${courseSectionId}`, params)
  }

  schoolAdminDeleteCourseSection(courseId, courseSectionId) {
    return axios.delete(`/schoolAdmin/courses/${courseId}/sections/${courseSectionId}`)
  }

  schoolAdminGetSchoolSettings() {
    return axios.get(`/schoolAdmin/schoolSettings`)
  }

  schoolAdminUpdateSchoolSettings(data) {
    return axios.put(`/schoolAdmin/schoolSettings/account`, data)
  }

  schoolAdminUpdatePaymentSchoolSettings(data) {
    return axios.post(`/schoolAdmin/schoolSettings/paymentSettings`, data)
  }

  schoolAdminUploadSchoolLogo(data) {
    return axios.post(`/schoolAdmin/schoolSettings/logo`, data)
  }

  schoolAdminAddLessonDuration(duration) {
    return axios.post(`/schoolAdmin/schoolSettings/lessonsDuration/add`, { duration })
  }

  schoolAdminChangeClassName(classId, name) {
    return axios.post(`/schoolAdmin/lessons/students/classes/${classId}/changeClassName`, { name })
  }

  schoolAdminRemoveLessonDuration(duration) {
    return axios.post(`/schoolAdmin/schoolSettings/lessonsDuration/remove`, { duration })
  }

  schoolAdminUpdateWorkingSchedule(schedule) {
    return axios.put(`/schoolAdmin/schoolSettings/workingSchedule`, { schedule })
  }

  schoolAdminHandbookList(params) {
    return axios.get('/schoolAdmin/handbook', { params })
  }

  schoolAdminHandbookGet(id) {
    return axios.get(`/schoolAdmin/handbook/${id}`)
  }

  schoolAdminAnalytics(params) {
    return axios.get(`/schoolAdmin/analytics`, { params })
  }

  schoolAdminSchedule(params) {
    return axios.get(`/schoolAdmin/schedule`, { params })
  }

  schoolAdminScheduleDetail(params) {
    return axios.get(`/schoolAdmin/schedule/detail`, { params })
  }

  schoolAdminScheduleTeachers(params) {
    return axios.get(`/schoolAdmin/schedule/teachers`, { params })
  }

  schoolAdminScheduleClasses(params) {
    return axios.get(`/schoolAdmin/schedule/classes`, { params })
  }

  schoolAdminScheduleCreateLesson(params) {
    return axios.post(`/schoolAdmin/schedule/lessons`, params)
  }

  teacherCreateCourseLesson(courseId, params) {
    return axios.post(`/teacher/courses/${courseId}/lessons`, params)
  }

  teacherDeleteCourseLesson(courseId, courseLessonId) {
    return axios.delete(`/teacher/courses/${courseId}/lessons/${courseLessonId}`)
  }

  teacherGetCourseLesson(courseId, courseLessonId) {
    return axios.get(`/teacher/courses/${courseId}/lessons/${courseLessonId}`)
  }

  teacherCreateLessonSection(courseId, lessonId, params) {
    return axios.post(`/teacher/courses/${courseId}/lessons/${lessonId}/sections`, params)
  }

  teacherDeleteLessonSection(courseId, lessonId, sectionId) {
    return axios.delete(`/teacher/courses/${courseId}/lessons/${lessonId}/sections/${sectionId}`)
  }

  teacherCreateLessonExercise(courseId, lessonId, sectionId, params) {
    return axios.post(`/teacher/courses/${courseId}/lessons/${lessonId}/sections/${sectionId}/exercises`, params)
  }

  teacherDeleteLessonExercise(courseId, lessonId, sectionId, exerciseId) {
    return axios.delete(`/teacher/courses/${courseId}/lessons/${lessonId}/sections/${sectionId}/exercises/${exerciseId}`)
  }

  // ADMIN
  adminSystemSettingsGet(params = {}) {
    return axios.get('/admin/system-settings', { params })
  }

  adminSystemSettingsUpdate(settings = []) {
    return axios.put('/admin/system-settings', { settings })
  }

  adminGetAdmins(params) {
    return axios.get('/admin/admins', { params })
  }

  adminInviteAdmin(email) {
    return axios.post('/admin/admins/invite', { email })
  }

  adminCreateAdmin(params) {
    return axios.post('/admin/admins', params)
  }

  adminGetAdmin(id) {
    return axios.get(`/admin/admins/${id}`)
  }

  adminUpdateAdmin(id, params) {
    return axios.put(`/admin/admins/${id}`, params)
  }

  adminDeleteAdmin(id) {
    return axios.delete(`/admin/admins/${id}`)
  }

  adminGetTeachers(params) {
    return axios.get('/admin/teachers', { params })
  }

  adminInviteTeacher(email) {
    return axios.post('/admin/teachers/invite', { email })
  }

  adminCreateTeacher(params) {
    return axios.post('/admin/teachers', params)
  }

  adminGetTeacher(id) {
    return axios.get(`/admin/teachers/${id}`)
  }

  adminUpdateTeacher(id, params) {
    return axios.put(`/admin/teachers/${id}`, params)
  }

  adminDeleteTeacher(id) {
    return axios.delete(`/admin/teachers/${id}`)
  }

  adminGetStudents(params) {
    return axios.get('/admin/students', { params })
  }

  adminInviteStudent(email) {
    return axios.post('/admin/students/invite', { email })
  }

  adminCreateStudent(params) {
    return axios.post('/admin/students', params)
  }

  adminGetStudent(id) {
    return axios.get(`/admin/students/${id}`)
  }

  adminUpdateStudent(id, params) {
    return axios.put(`/admin/students/${id}`, params)
  }

  adminDeleteStudent(id) {
    return axios.delete(`/admin/students/${id}`)
  }

  adminGetPlatformPlans(params) {
    return axios.get('/admin/platformPlans', { params })
  }

  adminGetOtherPlatformPlans(params) {
    return axios.get('/admin/platformPlans/otherPlans', { params })
  }

  adminCreatePlatformPlan(params) {
    return axios.post('/admin/platformPlans', params)
  }

  adminGetPlatformPlan(id) {
    return axios.get(`/admin/platformPlans/${id}`)
  }

  adminUpdatePlatformPlan(id, params) {
    return axios.put(`/admin/platformPlans/${id}`, params)
  }

  adminDeletePlatformPlan(id) {
    return axios.delete(`/admin/platformPlans/${id}`)
  }

  adminGetSchools(params) {
    return axios.get('/admin/schools', { params })
  }

  adminAnalyticsNewRegistrations(params) {
    return axios.get('/admin/analytics/newRegistrations', { params })
  }

  adminAnalyticsNewCoursesAndLessons(params) {
    return axios.get('/admin/analytics/newCoursesAndLessons', { params })
  }

  adminAnalyticsTopSchoolsByProfit(params) {
    return axios.get('/admin/analytics/topSchoolsByProfit', { params })
  }

  adminAnalyticsStudents(params) {
    return axios.get('/admin/analytics/students', { params })
  }

  adminCreateSchool(params) {
    return axios.post('/admin/schools', params)
  }

  adminGetSchool(id) {
    return axios.get(`/admin/schools/${id}`)
  }

  adminUpdateSchool(id, params) {
    return axios.put(`/admin/schools/${id}`, params)
  }

  adminDeleteSchool(id) {
    return axios.delete(`/admin/schools/${id}`)
  }

  adminSettingsSetInfo(firstName, lastName, email) {
    return axios.put('/admin/settings/info', { firstName, lastName, email })
  }

  adminSettingsSetPassword(currentPassword, newPassword, newPasswordConfirm) {
    return axios.put('/admin/settings/password', { currentPassword, newPassword, newPasswordConfirm })
  }

  adminGetPayments(params) {
    return axios.get('/admin/payments', { params })
  }

  adminHandbookList(params) {
    return axios.get('/admin/handbook', { params })
  }

  adminHandbookGet(id) {
    return axios.get(`/admin/handbook/${id}`)
  }

  adminHandbookCreate(params) {
    return axios.post('/admin/handbook', params)
  }

  adminHandbookEdit(id, params) {
    return axios.put(`/admin/handbook/${id}`, params)
  }

  adminHandbookDelete(id) {
    return axios.delete(`/admin/handbook/${id}`)
  }

  schoolAdminMarathonsList(params) {
    return axios.get('/schoolAdmin/marathons', { params })
  }

  schoolAdminMarathonsPlans(params) {
    return axios.get('/schoolAdmin/marathons/plans', { params })
  }

  schoolAdminMarathonsGet(id) {
    return axios.get(`/schoolAdmin/marathons/${id}`)
  }

  schoolAdminMarathonsCreate(params) {
    return axios.post('/schoolAdmin/marathons', params)
  }

  schoolAdminMarathonsInvite(id, params) {
    return axios.post(`/schoolAdmin/marathons/${id}/invite`, params)
  }

  schoolAdminMarathonsEdit(id, params) {
    return axios.put(`/schoolAdmin/marathons/${id}`, params)
  }

  schoolAdminMarathonsPay(id, params) {
    return axios.post(`/schoolAdmin/marathons/${id}/pay`, params)
  }

  schoolAdminMarathonsDelete(id) {
    return axios.delete(`/schoolAdmin/marathons/${id}`)
  }

  schoolAdminMarathonsMembers(id, params) {
    return axios.get(`/schoolAdmin/marathons/${id}/members`, { params })
  }

  schoolAdminMarathonsMembersDelete(id, userId, params) {
    return axios.delete(`/schoolAdmin/marathons/${id}/members/${userId}`, { params })
  }

  schoolAdminMarathonsMembersMakePayment(id, userId, params) {
    return axios.post(`/schoolAdmin/marathons/${id}/members/${userId}/makePayment`, { params })
  }

  schoolAdminMarathonsTeachersInvite(id, params) {
    return axios.post(`/schoolAdmin/marathons/${id}/teachers/invite`, params)
  }

  schoolAdminMarathonsTeachers(id, params) {
    return axios.get(`/schoolAdmin/marathons/${id}/teachers`, { params })
  }

  schoolAdminMarathonsTeachersDelete(id, userId, params) {
    return axios.delete(`/schoolAdmin/marathons/${id}/teachers/${userId}`, { params })
  }
}